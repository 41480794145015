/* Variables */
:root {
    --link_hover: #0F6AA2;
    --main-color: #0F6AA2;
    --text-color: #707070;
    --sec-color: #EDEDED;
    --gray-color: #4D4D4D;
    --danger-color: #dc3545;
    --unnamed-color-777777de: #777777DE;
    --unnamed-color-18c5bf: #18C5BF;
    --unnamed-color-ffffffde: #FFFFFFDE;
    --unnamed-color-4a4848: #4A4848;
    --unnamed-color-d8d4d4: #D8D4D4;
    --unnamed-color-f2f2f2: #F2F2F2;
    --unnamed-color-545454: #545454;
    --unnamed-color-212121de: #212121DE;
    --unnamed-color-000000de: #000000DE;
    --unnamed-color-000000: #000000;
    --light-color: #FFFFFF;
    --bckg-color: #F8F8F8;
    --font-size: 14px;
    --font-weight: 400;
    --font-family: 'Roboto', 'Droid Arabic Kufi', 'Arial', sans-serif;
  }